import request from '@/lib/request'

//查询用户的归属人社群码
export function getBelongerGroupCode(userId){
    return request({
        url: '/microService/marketplatform/agentGroupCode/getBelongerGroupCode?userId=' + userId,
        method: 'post',
        baseURL: false,
        timeout: 60000 // 60秒
    })
}
