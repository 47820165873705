<template>
  <div class="addGroupCode">
    <img :src="bg" alt="" class="addGroupCode_bg"/>
    <div class="addGroupCode_code_box">
      <img :src="addGroupCode_code" alt="" class="addGroupCode_code"/>
    </div>
  </div>
</template>
<script>
import {getBelongerGroupCode} from "@/api/activities/addGroupCode";
import {Toast} from "vant";

export default {
  data() {
    return {
      bg: require("@/assets/activities/addGroupCode_bg.png"),
      addGroupCode_code: "",
    }
  },
  created() {
    let {userId} = this.$route.query;
    console.log(userId);
    if (userId) {
      this.getBelongerGroupCodeFunc(userId)
    }
  },
  methods: {
    async getBelongerGroupCodeFunc(userId) {
      Toast.loading({
        message: "加载中",
        forbidClick: true,
        duration: 0,
      });
     getBelongerGroupCode(userId).then((data) => {
       Toast.clear();
       if (data.code === 200 && data.data) {
         this.addGroupCode_code = data.data
       }
     })
    },
  }
}
</script>
<style lang="less" scoped>
.addGroupCode {
  font-size: 0;
  position: relative;

  .addGroupCode_bg {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .addGroupCode_code_box {
    position: absolute;
    bottom: 20%;
    left: 0;
    width: 100%;
    object-fit: contain;
    text-align: center;

    .addGroupCode_code {
      width: 38%;
      height: auto;
      margin-left: 6%;
      padding: 5px;
    }
  }
}
</style>
